<template>
  <div class="tw-px-4">
    <div>
      <div class="tw-flex tw-w-full tw-justify-between tw-items-baseline">
        <h2 class="tw-ml-4 tw-uppercase">Summary</h2>
        <div class="tw-flex tw-items-baseline tw-justify-end tw-w-2/3">
          <p class="mr-2">
            {{
              activeFilterDateRange.id === null
                ? `From : ${$moment(filter.startTime).format(
                    "DD/MM/YYYY"
                  )} - ${$moment(filter.endTime).format("DD/MM/YYYY")}`
                : `From : ${activeFilterDateRange.name}`
            }}
          </p>
          <div v-if="!isLoadingButton" class=" ">
            <b-dropdown
              :text="
                activeFilterDateRange.name
                  ? `${activeFilterDateRange.name} `
                  : 'กำหนดเอง '
              "
              ref="dropdownFilterBookingReport"
              @hide="handleHide($event)"
              @hidden="isCloseable = false"
              dropright
              variant="link"
              no-caret
            >
              <template #button-content>
                <font-awesome-icon icon="filter" class="primary-text pointer" />
              </template>
              <div
                class=""
                v-for="(item, index) in option"
                :key="`option-${index}`"
              >
                <div
                  class=""
                  v-if="item.id !== null && item.name !== 'กำหนดเอง'"
                >
                  <b-dropdown-item
                    @click="changeFilterDateRange(item)"
                    :class="[
                      activeFilterDateRange.id === item.id && 'primary-color ',
                    ]"
                    >{{ item.name }}</b-dropdown-item
                  >
                </div>
                <div
                  v-else
                  class="hover:tw-bg-gray-200"
                  :class="[
                    activeFilterDateRange.id === item.id && 'primary-color ',
                  ]"
                >
                  <!-- <hr /> -->
                  <div class="tw-mt-2 tw-pt-2">
                    <p class="tw-px-5">กำหนดเอง</p>
                    <div class="tw-flex tw-flex-row tw-flex-wrap tw-px-2">
                      <div class="tw-w-full tw-px-2">
                        <p class="tw-px-4">เริ่มต้น</p>
                        <DatePicker
                          v-model="filter.startTime"
                          valueType="format"
                        />
                      </div>
                      <div class="tw-w-full tw-px-2 tw-mt-2 tw-mb-4">
                        <p class="tw-px-4">สิ้นสุด</p>
                        <DatePicker
                          v-model="filter.endTime"
                          :disabled="!filter.startTime"
                          valueType="format"
                          :disabled-date="disabledRange"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <b-dropdown-item> -->
              <div class="tw-px-2 tw-mt-2">
                <b-button variant="danger" class="tw-w-full" @click="closeMe()"
                  >Close</b-button
                >
              </div>
              <!-- </b-dropdown-item> -->
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <Summary :summary="summary" :loading="isLoading" />
    <div class="tw-flex tw-justify-between tw-mt-6">
      <div></div>
      <b-button class="btn-filter mr-2" @click="handleClickFilter">
        <span class="d-none d-md-block">
          <font-awesome-icon icon="filter" class="pointer" />
          <span class="">Filter</span>
        </span>
      </b-button>
    </div>
    <div><line-chart :filter="filterSideBar" ref="lineChart" /></div>
    <div class="tw-mt-4">
      <div><TableLocation ref="tableLocation" :filter="filterSideBar" /></div>
      <hr />
      <div><TableBrand ref="tableBrand" :filter="filterSideBar" /></div>
      <hr />
      <div>
        <TableServiceType ref="tableServiceType" :filter="filterSideBar" />
      </div>
      <hr />
      <div><TableService ref="tableService" :filter="filterSideBar" /></div>
      <hr />
      <div>
        <TableBookingReport ref="tableTransaction" :filter="filterSideBar" />
      </div>
    </div>

    <filter-booking-report
      :visible="visible"
      :filter="filterSideBar"
      :option="option"
      @clearForm="clearForm"
      @clearFilter="clearFilter"
      @searchAll="searchAll"
    />
  </div>
</template>

<script>
import Summary from "./component/summary.vue";
import LineChart from "./component/lineChart.vue";
import FilterBookingReport from "./component/filter.vue";
import TableLocation from "./component/tableLocation.vue";
import TableBrand from "./component/tableBrand.vue";
import TableServiceType from "./component/tableServiceType.vue";
import TableService from "./component/tableService.vue";
import TableBookingReport from "./component/tableBookingReport.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: "BookingReportTab",
  components: {
    Summary,
    LineChart,
    FilterBookingReport,
    TableLocation,
    TableBrand,
    TableServiceType,
    TableService,
    TableBookingReport,
    DatePicker,
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
    option: {
      type: Array,
      required: true,
    },
    isLoadingButton: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      filterSideBar: {
        startCreateDate: null,
        endCreateDate: null,
        statusId: [],
        brandId: [],
        venueId: [],
        categoryId: [],
        eventId: [],
        startBookingTime: null,
        endBookingTime: null,
        startBookingDate: null,
        endBookingDate: null,
        keyword: "",
        dateRangeId: 0,
      },
      isCloseable: false,
      isLoading: false,
      summary: {},
      filter: {
        dateRangeId: 0,
        startTime: null,
        endTime: null,
      },
    };
  },
  watch: {
    "filter.dateRangeId"(val) {
      if (val !== null) {
        this.filter.startTime = null;
        this.filter.endTime = null;
      }
      this.closeMe();
    },
    "filter.startTime"(val) {
      if (val === null) {
        this.filter.endTime = null;
        this.filter.dateRangeId = 0;
        this.getSummary();
        this.closeMe();
      }
    },
    "filter.endTime"(val) {
      if (val === null) return;
      this.filter.dateRangeId = null;
      this.getSummary();
      this.closeMe();
    },
  },
  computed: {
    activeFilterDateRange() {
      return this.option.length > 0
        ? this.option.find((item) => {
            return item.id === this.filter.dateRangeId;
          })
        : {};
    },
  },
  mounted() {
    this.getSummary();
  },
  methods: {
    async getSummary() {
      this.isLoading = true;
      const payload = {
        ...this.filter,
        dateRangeId:
          this.filter.dateRangeId === 0 ? null : this.filter.dateRangeId,
      };
      const respone = await this.$services.dashboard.getSummary(payload);
      this.summary = respone.detail;
      setTimeout(() => {
        this.isLoading = false;
      }, 400);
    },
    fetchTableComponents() {
      this.$refs.tableLocation.fetchTableBranch();
      this.$refs.tableBrand.fetchTableBrand();
      this.$refs.tableService.fetchTableService();
      this.$refs.tableServiceType.fetchTableCategory();
      this.$refs.tableTransaction.fetchTableTransaction();
      this.$refs.lineChart.getDataChart();
    },
    searchAll() {
      this.fetchTableComponents();
      this.visible = false;
    },
    handleClickFilter() {
      this.visible = true;
    },
    clearForm() {
      this.visible = false;
    },
    clearFilter() {
      this.filterSideBar = {
        startCreateDate: null,
        endCreateDate: null,
        booking: {},
        statusId: [],
        brandId: [],
        venueId: [],
        categoryId: [],
        eventId: [],
        startBookingTime: "",
        endBookingTime: null,
        startBookingDate: "",
        endBookingDate: null,
        keyword: "",
        dateRangeId: 0,
      };
      setTimeout(() => {
        this.searchAll();
      }, 1000);
    },
    handleHide(bvEvent) {
      if (!this.isCloseable) {
        bvEvent.preventDefault();
      } else {
        this.$refs.dropdownFilterBookingReport.hide();
      }
    },
    closeMe() {
      this.isCloseable = true;
      this.$refs.dropdownFilterBookingReport.hide();
    },
    disabledRange: function (date) {
      const selectedStartTime = this.filter.startTime
        ? new Date(this.filter.startTime).getTime()
        : null;

      return selectedStartTime && date < selectedStartTime;
    },
    changeFilterDateRange(val) {
      let { id } = val;

      this.filter.dateRangeId = id;

      this.getSummary();
    },
  },
};
</script>

<style lang="scss" scoped></style>
