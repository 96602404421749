<template>
  <div>
    <SideBarFilter
      :filter="filter"
      ref="sidebarFilterBookingReport"
      placeholder="BookingReport"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      @clearForm="clearForm"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      handleSideBar
    >
      <template v-slot:filter-option>
        <div class="mt-2">
          <InputTag
            :options="optionBrand"
            label="Brand"
            :vmodel="filter.brandId"
            :optionAll="optionAllBrand"
            choseAll
            @onChangeAll="
              () => {
                optionAllBrand = !optionAllBrand;
                if (optionAllBrand === true) {
                  filter.brandId = [];
                }
              }
            "
            :isValidate="$v.filter.brandId.$error"
            :v="$v.filter.brandId"
            @onSearch="handleSearch($event, 'brand')"
            useServerOption
          />
        </div>
        <div class="mt-2">
          <InputTag
            :options="optionVenue"
            label="Branch"
            :vmodel="filter.venueId"
            :optionAll="optionAllVenue"
            choseAll
            @onChangeAll="
              () => {
                optionAllVenue = !optionAllVenue;
                if (optionAllVenue === true) {
                  filter.venueId = [];
                }
              }
            "
            @onSearch="handleSearch($event, 'venue')"
            :isValidate="$v.filter.venueId.$error"
            :v="$v.filter.venueId"
            useServerOption
          />
        </div>
        <!-- <div class="d-flex mt-4">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่สร้างเริ่มต้น</div>
            <div :class="['input-container']">
              <datetime
                v-model="filter.startCreateDate"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="startDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mx-1"></div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่สร้างสิ้นสุด</div>
            <div :class="['input-container']">
              <datetime
                v-model="filter.endCreateDate"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="endDate"
                :disabled="!filter.startCreateDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="text-error" v-if="$v.filter.startDate.endTime.$error">
              กรุณาเลือก
            </p>
          </div>
        </div> -->
        <div class="">
          <InputTag
            :options="optionCustomerFlowStatus"
            label="Status"
            :vmodel="filter.statusId"
            :optionAll="optionAllStatus"
            choseAll
            @onChangeAll="
              () => {
                optionAllStatus = !optionAllStatus;
                if (optionAllStatus === true) {
                  filter.statusId = [];
                }
              }
            "
            :isValidate="$v.filter.statusId.$error"
            :v="$v.filter.statusId"
          />
        </div>
        <div class="mt-3">
          <InputTag
            :options="optionCategory"
            label="Service Category"
            :vmodel="filter.categoryId"
            :optionAll="optionAllServiceType"
            choseAll
            @onChangeAll="
              () => {
                optionAllServiceType = !optionAllServiceType;
                if (optionAllServiceType === true) {
                  filter.categoryId = [];
                }
              }
            "
            :isValidate="$v.filter.categoryId.$error"
            :v="$v.filter.categoryId"
            @onSearch="handleSearch($event, 'category')"
            useServerOption
          />
        </div>
        <div class="mt-2">
          <InputTag
            :options="optionEvent"
            label="Event"
            :vmodel="filter.eventId"
            :optionAll="optionAllEvent"
            choseAll
            @onChangeAll="
              () => {
                optionAllEvent = !optionAllEvent;
                if (optionAllEvent === true) {
                  filter.eventId = [];
                }
              }
            "
            :isValidate="$v.filter.eventId.$error"
            :v="$v.filter.eventId"
            @onSearch="handleSearch($event, 'event')"
            useServerOption
          />
        </div>
        <div class="my-2">
          <InputSelect
            title="Date"
            name="type"
            class="mt-2"
            v-model="filter.dateRangeId"
            v-bind:options="option"
            valueField="id"
            textField="name"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Date --</b-form-select-option
              >
              <!-- <b-form-select-option :value="0">All</b-form-select-option> -->
            </template>
          </InputSelect>
        </div>
        <div class="d-flex mt-4" v-if="filter.dateRangeId === null">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">
              วันที่ Booking {{ filter.dateRangeId }}
            </div>
            <div :class="['input-container']">
              <datetime
                type="date"
                v-model="filter.startBookingDate"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="Asia/Bangkok"
                placeholder="Please Select Date"
                ref="filterStartDateTime"
                :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                use24-hour
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.filterStartDateTime.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="text-error" v-if="$v.filter.startBookingDate.$error">
              กรุณาเลือก
            </p>
          </div>
          <div class="mx-1"></div>

          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่สิ้นสุด Booking</div>
            <div :class="['input-container']">
              <datetime
                type="date"
                v-model="filter.endBookingDate"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="Asia/Bangkok"
                placeholder="Please Select Date"
                ref="filterEndDateTime"
                :disabled="!filter.startBookingDate"
                :min-datetime="minEndBookingDate"
                :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                use24-hour
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.filterEndDateTime.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="text-error" v-if="$v.filter.endBookingDate.$error">
              กรุณาเลือก
            </p>
          </div>
        </div>

        <div class="d-flex mt-2">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">เวลาที่ Booking</div>
            <div :class="['input-container']">
              <datetime
                type="time"
                v-model="filter.startBookingTime"
                :input-style="styleDatetime"
                format="HH:mm:ss"
                value-zone="Asia/Bangkok"
                placeholder="Please Select Date"
                ref="filterStartTime"
                :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                use24-hour
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.filterStartTime.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mx-1"></div>

          <div class="mb-3">
            <div class="font-weight-bold mb-2">เวลาสิ้นสุด Booking</div>
            <div :class="['input-container']">
              <datetime
                type="time"
                v-model="filter.endBookingTime"
                :input-style="styleDatetime"
                format="HH:mm:ss"
                value-zone="Asia/Bangkok"
                placeholder="Please Select Date"
                ref="filterEndTime"
                :disabled="!filter.startBookingTime"
                :min-datetime="minEndBookingTime"
                :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                use24-hour
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.filterEndTime.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="text-error" v-if="$v.filter.endBookingTime.$error">
              กรุณาเลือก
            </p>
          </div>
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import AutocompleteCustom from "@/components/inputs/AutocompleteCustom";
import InputTag from "@/components/inputs/InputTag.vue";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
export default {
  name: "FilterBookingReport",
  components: { AutocompleteCustom, InputTag },
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
    filter: {
      type: Object,
      required: true,
    },
    option: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      optionBrand: [],
      optionVenue: [],
      optionCustomerFlowStatus: [],
      optionCategory: [],
      optionEvent: [],
      optionsRoundTime: [],

      optionAllBrand: true,
      optionAllVenue: true,
      optionAllStatus: true,
      optionAllServiceType: true,
      optionAllEvent: true,

      minEndBookingDate: null,
      minEndBookingTime: null,

      timer: null,
      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      // state delay
      isSearchEvent: null,
    };
  },
  validations() {
    return {
      filter: {
        brandId: {
          required: requiredIf(() => {
            return this.optionAllBrand === false;
          }),
        },
        venueId: {
          required: requiredIf(() => {
            return this.optionAllVenue === false;
          }),
        },
        categoryId: {
          required: requiredIf(() => {
            return this.optionAllServiceType === false;
          }),
        },
        eventId: {
          required: requiredIf(() => {
            return this.optionAllEvent === false;
          }),
        },
        statusId: {
          required: requiredIf(() => {
            return this.optionAllStatus === false;
          }),
        },
        startBookingDate: {
          required: requiredIf(() => {
            return this.filter.dateRangeId === null;
          }),
        },
        endBookingDate: {
          required: requiredIf(() => {
            return (
              this.filter.startBookingDate !== "" &&
              this.filter.endBookingDate === ""
            );
          }),
        },
        endBookingTime: {
          required: requiredIf(() => {
            return (
              this.filter.startBookingTime !== "" &&
              this.filter.endBookingTime === ""
            );
          }),
        },
      },
    };
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.getOptionBrand();
        this.getOptionVenue();
        this.getOptionStatus();
        this.getOptionCategory();
        this.$refs.sidebarFilterBookingReport.show();
      } else {
        this.$refs.sidebarFilterBookingReport.hide();
      }
    },
    "filter.categoryId"(val) {
      this.getOptionEventGroupInCategory();
    },
    "filter.startBookingDate"(val) {
      if (val !== "") {
        this.minEndBookingDate = new Date(val).toISOString();
        this.filter.endBookingDate = "";
      }
    },
    "filter.startBookingTime"(val) {
      if (val !== "") {
        this.minEndBookingTime = new Date(val).toISOString();
        this.filter.endBookingTime = "";
      }
    },
    "filter.dateRangeId"(val) {
      if (val === null) {
        this.filter.startBookingDate = "";
        this.filter.endBookingDate = "";
      }
    },
  },
  methods: {
    async getOptionBrand(keyword) {
      const brandData = await this.$services.master.getOptionBrand(keyword);
      if (brandData.result === 1) {
        this.optionBrand = brandData.detail;
      }
    },
    async getOptionVenue(keyword) {
      const venueData = await this.$services.master.getOptionVenue(keyword);
      if (venueData.result === 1) {
        this.optionVenue = venueData.detail;
      }
    },
    async getOptionStatus() {
      const respone = await this.$services.master.getOptionCustomerStatusFlow();
      if (respone.result === 1) {
        this.optionCustomerFlowStatus = respone.detail;
      }
    },
    async getOptionCategory(keyword) {
      const categoryData = await this.$services.master.getOptionCategory(
        keyword
      );
      if (categoryData.result === 1) {
        this.optionCategory = categoryData.detail;
      }
    },
    async getOptionEventGroupInCategory(keyword) {
      const payload = {
        categoryList: this.filter.categoryId,
        search: keyword,
      };
      const respone = await this.$services.master.GetOptionEventGroupInCategory(
        payload
      );
      this.optionEvent = respone.detail;
    },
    searchAll() {
      this.$v.filter.$touch();

      if (this.$v.filter.$error) {
        return;
      }

      this.$emit("searchAll");
    },
    handleSearch(val, type) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        switch (type) {
          case "venue":
            this.getOptionVenue(val);
            break;
          case "category":
            this.getOptionEventGroupInCategory(val);
            break;
          case "event":
            this.getOptionEventGroupInCategory(val);
            break;
          case "brand":
            this.getOptionBrand(val);
            break;
          default:
            break;
        }
      }, 1000);
    },
    clearFilter() {
      this.$v.filter.$reset();
      this.optionAllBrand = true;

      this.optionAllStatus = true;
      this.optionAllServiceType = true;
      this.optionAllEvent = true;
      this.optionAllVenue = true;
      this.$emit("clearFilter");
    },
    clearForm() {
      this.$v.filter.$reset();
      this.$emit("clearForm");
    },
    handleInputEvent() {},
  },
};
</script>

<style lang="scss" scoped></style>
