<template>
  <div class="">
    <div class="header-eventgroup">
      <h1 class="tw-p-4 tw-uppercase">Dashboard</h1>
    </div>
    <!-- <b-button @click="test()">sss</b-button> -->

    <div class="tw-bg-white tw-min-h-screen tw-mt-4">
      <b-tabs
        content-class="mt-3"
        active-nav-item-class="font-weight-bold "
        justified
        v-model="tabIndex"
      >
        <b-tab title="Booking Report">
          <div class="" v-if="tabIndex === 0">
            <BookingReport
              :tabIndex="tabIndex"
              :option="optionDateSummary"
              :isLoadingButton="isLoadingButton"
            /></div
        ></b-tab>
        <b-tab title="Register Report">
          <div class="" v-if="tabIndex === 1">
            <RegisterReport
              :tabIndex="tabIndex"
              ref="registerReport"
              :option="optionDateSummary"
            /></div
        ></b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import BookingReport from "./component/bookingReport/index.vue";
import RegisterReport from "./component/registerReport/index.vue";

import PieChart from "./component/registerReport/component/pieChart.vue";

export default {
  name: "DashboardPage",
  components: { BookingReport, RegisterReport, PieChart },
  data() {
    return {
      tabIndex: 0,
      isLoadingButton: false,
      isCloseable: false,

      optionDateSummary: [],

      tabs: [
        { index: 0, name: "Booking Report" },
        { index: 1, name: "Register Report" },
      ],
    };
  },
  mounted() {
    this.getOptionDateSummary();
  },

  methods: {
    async getOptionDateSummary() {
      this.isLoadingButton = true;

      const respone = await this.$services.dashboard.getSummaryDateOption();
      this.optionDateSummary = respone.detail;
      const firstOption = {
        id: 0,
        name: "ทั้งหมด",
      };
      this.optionDateSummary = [firstOption, ...this.optionDateSummary];
      this.isLoadingButton = false;
    },
    // test() {
    //   const parameters = {
    //     test: "aaaa",
    //     id: 1,
    //   };
    //   const apiName = "Report/Summary";
    //   const body = null;
    //   const signMethod = "SHA256";
    //   const appSecret = "aaa";
    //   // 1. จัดเรียงพารามิเตอร์ตามตัวอักษร ASCII
    //   let sortedParams = Object.keys(parameters).sort();
    //   let sortedParamsObj = {};
    //   sortedParams.forEach((key) => {
    //     sortedParamsObj[key] = parameters[key];
    //   });

    //   // 2. สร้างข้อความที่มีลำดับพารามิเตอร์เช่นเดียวกับตอนสร้างและเข้ารหัสสตริง
    //   let query = apiName;
    //   for (let key in sortedParamsObj) {
    //     if (sortedParamsObj[key]) {
    //       query += key + sortedParamsObj[key];
    //     }
    //   }

    //   // 3. สร้างคีย์สำหรับเข้ารหัส HMAC
    //   if (body) {
    //     query += body;
    //   }

    //   // 4. เข้ารหัสสตริงด้วย HMAC SHA256
    //   let bytes = null;
    //   if (signMethod === "SHA256") {
    //     const crypto = require("crypto");
    //     const hmac = crypto.createHmac("sha256", appSecret);
    //     hmac.update(query);
    //     bytes = hmac.digest();
    //   }

    //   // 5. แปลงข้อมูลไบนารีเป็นรหัส Hexadecimal
    //   let result = "";
    //   for (let i = 0; i < bytes.length; i++) {
    //     result += bytes[i].toString(16).padStart(2, "0");
    //   }

    //   console.log(result);
    // },
  },
};
</script>

<style lang="scss">
.footer-event-setting {
  display: flex;
  justify-content: space-between;
}
.nav-link.active {
  color: var(--secondary-color) !important;
  border-bottom: 4px solid var(--secondary-color) !important;
}
.primary-text {
  color: var(--secondary-color) !important;
}
.nav-link:hover {
  border-color: transparent transparent var(--secondary-color) transparent !important;
}
.primary-color {
  background-color: var(--secondary-color);

  & a {
    color: white !important;
  }
  & p {
    color: white !important;
  }
}
</style>
