<template>
  <div>
    <div class="header-title">
      <h1 class="title">Booking by Service</h1>
      <b-button class="btn-filter">
        <span class="d-none d-md-block">
          <span
            v-if="!loadingExport"
            class=""
            @click="handleClickExport"
            :disabled="loadingExport"
            >Export</span
          >
          <b-spinner v-else class="align-middle"></b-spinner>
        </span>
      </b-button>
    </div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(totalSpend)="{ item }">
        <p class="m-0">
          {{ formatNumber(item.totalSpend) }}
        </p>
      </template>
      <template #cell(firstName)="{ item }">
        <p class="m-0">
          {{ item.firstName ? `${item.firstName} ${item.lastName}` : "-" }}
        </p>
      </template>
      <template #cell(email)="{ item }">
        <p class="m-0">
          {{ item.email ? `${email}` : "-" }}
        </p>
      </template>
      <template #cell(birthDay)="{ item }">
        <p class="m-0">
          {{
            item.birthDay
              ? $moment(item.birthDay).locale("th").format("DD/MM/YYYY")
              : "-"
          }}
        </p>
      </template>
      <template #cell(createdTime)="{ item }">
        <p class="m-0">
          {{
            $moment(item.createdTime).locale("th").format("DD/MM/YYYY : HH:MM")
          }}
        </p>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template></b-table
    >
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filterPagination"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  name: "TableRegister",
  components: { Pagination },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      table: "Customer",
      items: [],
      isBusy: false,
      fields: [
        {
          key: "totalSpend",
          label: "GrandTotal",
          class: "w-50px text-nowrap",
        },
        {
          key: "firstName",
          label: "ชื่อ - นามสกุล",
          class: "w-50px text-nowrap",
        },
        {
          key: "email",
          label: "อีเมล",
          class: "w-50px text-nowrap",
        },
        {
          key: "telephone",
          label: "เบอร์โทร",
          class: "w-50px text-nowrap",
        },
        {
          key: "birthDay",
          label: "วันเกิด",
          class: "w-50px text-nowrap",
        },
        {
          key: "createdTime",
          label: "วันที่ Register",
          class: "w-50px text-nowrap",
        },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filterPagination: { skip: 1, take: 10, page: 1 },
      rows: 0,
      loadingExport: false,
    };
  },
  mounted() {
    this.fetchTableCustomer();
  },
  methods: {
    async handleClickExport() {
      this.loadingExport = true;
      const payload = {
        startDate: this.filter.startDate === "" ? null : this.filter.startDate,
        endDate: this.filter.endDate === "" ? null : this.filter.endDate,
        keyword: "",
        dateRangeId:
          this.filter.dateRangeId === 0 ? null : this.filter.dateRangeId,
      };
      const getData = await this.$services.dashboard.exportReportExcel(
        this.table,
        payload
      );

      this.resolveAndDownloadBlob(getData, `${this.table}`);
      this.loadingExport = false;
    },
    async fetchTableCustomer() {
      this.isBusy = true;
      const payload = {
        startDate: this.filter.startDate === "" ? null : this.filter.startDate,
        endDate: this.filter.endDate === "" ? null : this.filter.endDate,
        keyword: "",
        page: this.filterPagination.page,
        take: this.filterPagination.take,
        dateRangeId:
          this.filter.dateRangeId === 0 ? null : this.filter.dateRangeId,
      };
      const respone = await this.$services.dashboard.getReportCustomer(payload);
      this.items = respone.detail.data;
      this.rows = respone.detail.count;
      this.isBusy = false;
    },
    handleChangeTake(value) {
      this.filterPagination.page = 1;
      this.filterPagination.take = value;
      this.fetchTableCustomer();
    },
    pagination(page) {
      this.filterPagination.page = page;
      this.fetchTableCustomer();
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
    resolveAndDownloadBlob(v, filename) {
      return this.$services.utils.resolveAndDownloadBlob(v, filename);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
}
.title {
  font-size: 18px;
}
</style>
