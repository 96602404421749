<template>
  <div class="tw-mt-4">
    <div class="text-center text-black my-2" v-if="isLoading === true">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">Loading...</strong>
    </div>
    <div v-else class="tw-py-4 tw-mb-6">
      <Chart :id="id" :label="labels" type="pie" :chartData="datasets" />
    </div>
  </div>
</template>

<script>
import Chart from "@/components/form/chart.vue";

export default {
  name: "RegisterReportPieChart",
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  components: { Chart },
  data() {
    return {
      id: "register-pir-chart",
      labels: [],
      datasets: [],
      isLoading: false,
    };
  },

  mounted() {
    this.getPieData();
  },
  methods: {
    async getPieData() {
      this.isLoading = true;

      const payload = {
        startDate: this.filter.startDate === "" ? null : this.filter.startDate,
        endDate: this.filter.endDate === "" ? null : this.filter.endDate,
        keyword: "",
        dateRangeId:
          this.filter.dateRangeId === 0 ? null : this.filter.dateRangeId,
      };
      this.$services.dashboard
        .getReportPieCustomer(payload)
        .then((respone) => {
          this.labels = respone.detail.labels;
          this.datasets = respone.detail.datasets;
        })
        .finally(() => {
          this.isLoading = false;
        });
      // const respone = await this.$services.dashboard.getReportPieCustomer(
      //   payload
      // );
    },
  },
};
</script>

<style lang="scss"></style>
