<template>
  <div>
    <SideBarFilter
      :filter="filter"
      ref="sidebarFilterRegisterReport"
      placeholder="BookingReport"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      @clearForm="clearForm"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      handleSideBar
    >
      <template v-slot:filter-option>
        <div class="my-2">
          <InputSelect
            title="Date"
            name="type"
            class="mt-2"
            v-model="filter.dateRangeId"
            v-bind:options="option"
            valueField="id"
            textField="name"
            @onDataChange="onChangeOption($event)"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Date --</b-form-select-option
              >
            </template>
          </InputSelect>
        </div>
        <div class="d-flex mt-4" v-if="filter.dateRangeId === null">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่สร้างเริ่มต้น</div>
            <div :class="['input-container']">
              <datetime
                v-model="filter.startDate"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="startDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="text-error" v-if="$v.filter.startDate.$error">กรุณาเลือก</p>
          </div>
          <div class="mx-1"></div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่สร้างสิ้นสุด</div>
            <div :class="['input-container']">
              <datetime
                v-model="filter.endDate"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="endDate"
                :disabled="!filter.startDate"
                :min-datetime="minDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="text-error" v-if="$v.filter.endDate.$error">กรุณาเลือก</p>
          </div>
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
export default {
  name: "FilterRegisterReport",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    option: {
      type: Array,
      required: true,
    },
  },
  validations() {
    return {
      filter: {
        startDate: {
          required: requiredIf(() => {
            return this.filter.dateRangeId === null;
          }),
        },
        endDate: {
          required: requiredIf(() => {
            return this.filter.startDate;
          }),
        },
      },
    };
  },
  data() {
    return {
      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      disableForm: false,
      // minDate: null,
    };
  },
  computed: {
    minDate() {
      if (this.filter.startDate) {
        return new Date(this.filter.startDate).toISOString();
      }
    },
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.$refs.sidebarFilterRegisterReport.show();
      } else {
        this.$refs.sidebarFilterRegisterReport.hide();
      }
    },
    "filter.startDate"(val) {
      if (val !== "") {
        this.filter.endDate = "";
      }
    },
  },
  methods: {
    searchAll() {
      this.$v.filter.$touch();

      if (this.$v.filter.$error) {
        return;
      }
      this.$emit("searchAll");
    },
    onChangeOption(event) {
      if (event === null) {
        this.disableForm = true;
      } else {
        this.disableForm = false;
        this.filter.startDatetest = null;
        this.filter.endDatetest = null;
      }
    },
    clearForm() {
      this.$v.filter.$reset();
      this.$emit("clearForm");
    },
    clearFilter() {
      this.$v.filter.$reset();
      this.$emit("clearFilter");
    },
  },
};
</script>

<style lang="scss" scoped></style>
