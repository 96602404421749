<template>
  <div>
    <div class="header-title">
      <h1 class="title">Booking by Service</h1>
      <b-button class="btn-filter">
        <span class="d-none d-md-block">
          <span
            v-if="!loadingExport"
            class=""
            @click="handleClickExport"
            :disabled="loadingExport"
            >Export</span
          >
          <b-spinner v-else class="align-middle"></b-spinner>
        </span>
      </b-button>
    </div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(totalSpend)="{ item }">
        <p class="m-0">
          {{ formatNumber(item.totalSpend) }}
        </p>
      </template>
      <template #cell(totalBooking)="{ item }">
        <p class="m-0">
          {{ formatNumber(item.totalBooking) }}
        </p>
      </template>
      <template #cell(startDate)="{ item }">
        <p class="m-0">
          {{
            $moment(item.startDate).locale("th").format("DD/MM/YYYY : HH:MM")
          }}
        </p>
      </template>
      <template #cell(endDate)="{ item }">
        <p class="m-0">
          {{ $moment(item.endDate).locale("th").format("DD/MM/YYYY : HH:MM") }}
        </p>
      </template>
      <template v-slot:custom-foot>
        <tr>
          <td class="bg-primary"></td>
          <td class="bg-primary"></td>
          <td class="bg-primary"></td>
          <td class="bg-primary">{{ formatNumber(totalSpend) }}</td>
          <td class="bg-primary">{{ formatNumber(totalBooking) }}</td>
        </tr>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template></b-table
    >
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filterPagination"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  name: "TableService",
  components: { Pagination },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      table: "EventGroup",
      items: [],
      isBusy: false,
      fields: [
        {
          key: "name",
          label: "ServiceName",
          class: "w-50px text-nowrap",
        },
        {
          key: "startDate",
          label: "StartDate",
          class: "w-50px text-nowrap",
        },
        {
          key: "endDate",
          label: "EndDate",
          class: "w-50px text-nowrap",
        },
        {
          key: "totalSpend",
          label: "Total Spend",
          class: "w-50px text-nowrap",
        },
        {
          key: "totalBooking",
          label: "Total Booking",
          class: "w-50px text-nowrap",
        },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filterPagination: { skip: 1, take: 10, page: 1 },
      rows: 0,

      totalSpend: 0,
      totalBooking: 0,
      loadingExport: false,
    };
  },
  mounted() {
    this.fetchTableService();
  },
  methods: {
    async handleClickExport() {
      this.loadingExport = true;
      const payload = {
        ...this.filter,
        startCreateDate:
          this.filter.startCreateDate === ""
            ? null
            : this.filter.startCreateDate,
        endCreateDate:
          this.filter.endCreateDate === "" ? null : this.filter.endCreateDate,
        statusId:
          this.filter.statusId.length <= 0 ? null : this.filter.statusId,
        brandId: this.filter.brandId.length <= 0 ? null : this.filter.brandId,
        venueId: this.filter.venueId.length <= 0 ? null : this.filter.venueId,
        categoryId:
          this.filter.categoryId.length <= 0 ? null : this.filter.categoryId,
        eventId: this.filter.eventId.length <= 0 ? null : this.filter.eventId,
        endBookingDate:
          this.filter.endBookingDate === "" ? null : this.filter.endBookingDate,
        startBookingDate:
          this.filter.startBookingDate === ""
            ? null
            : this.filter.startBookingDate,
        startBookingTime:
          this.filter.startBookingTime === "" ||
          this.filter.startBookingTime === null
            ? null
            : this.$moment(this.filter.startBookingTime).format("HH:mm:ss") ||
              null,
        endBookingTime:
          this.filter.endBookingTime === "" ||
          this.filter.endBookingTime === null
            ? null
            : this.$moment(this.filter.endBookingTime).format("HH:mm:ss") ||
              null,
      };
      const getData = await this.$services.dashboard.exportReportExcel(
        this.table,
        payload
      );

      this.resolveAndDownloadBlob(getData, `${this.table}`);
      this.loadingExport = false;
    },
    async fetchTableService() {
      this.isBusy = true;
      const payload = {
        ...this.filterPagination,
        ...this.filter,
        startCreateDate:
          this.filter.startCreateDate === ""
            ? null
            : this.filter.startCreateDate,
        endCreateDate:
          this.filter.endCreateDate === "" ? null : this.filter.endCreateDate,
        statusId:
          this.filter.statusId.length <= 0 ? null : this.filter.statusId,
        brandId: this.filter.brandId.length <= 0 ? null : this.filter.brandId,
        venueId: this.filter.venueId.length <= 0 ? null : this.filter.venueId,
        categoryId:
          this.filter.categoryId.length <= 0 ? null : this.filter.categoryId,
        eventId: this.filter.eventId.length <= 0 ? null : this.filter.eventId,
        endBookingDate:
          this.filter.endBookingDate === "" ||
          this.filter.endBookingDate === null
            ? null
            : this.filter.endBookingDate,
        startBookingDate:
          this.filter.startBookingDate === "" ||
          this.filter.startBookingDate === null
            ? null
            : this.filter.startBookingDate,
        startBookingTime:
          this.filter.startBookingTime === "" ||
          this.filter.startBookingTime === null
            ? null
            : this.$moment(this.filter.startBookingTime).format("HH:mm:ss") ||
              null,
        endBookingTime:
          this.filter.endBookingTime === "" ||
          this.filter.endBookingTime === null
            ? null
            : this.$moment(this.filter.endBookingTime).format("HH:mm:ss") ||
              null,
      };

      const respone = await this.$services.dashboard.getReportEventGroup(
        payload
      );
      this.items = respone.detail.data;
      this.rows = respone.detail.count;
      this.totalSpend = respone.detail.totalSpend;
      this.totalBooking = respone.detail.totalBooking;
      this.isBusy = false;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.fetchTableService();
    },
    pagination(page) {
      this.filter.page = page;
      this.fetchTableService();
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
    resolveAndDownloadBlob(v, filename) {
      return this.$services.utils.resolveAndDownloadBlob(v, filename);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
}
.title {
  font-size: 18px;
}
.bg-primary {
  background-color: var(--secondary-color) !important;
  color: white;
}
</style>
