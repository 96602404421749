<template>
  <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-5 tw-mt-4">
    <div
      v-for="(item, index) in entriesSummary"
      :key="`summary-${index}`"
      :class="{
        'tw-rounded-none tw-border-solid tw-border-r-2 tw-border-t-0 tw-border-b-0 tw-border-l-0 tw-border-gray-200 tw-bg-white tw-overflow-hidden':
          index !== entriesSummary.length - 1,
        ' tw-bg-white tw-overflow-hidden': index === entriesSummary.length - 1,
      }"
    >
      <div class="tw-px-4 tw-py-0 sm:tw-p-4">
        <dl>
          <dt
            class="tw-text-base tw-uppercase tw-leading-5 tw-font-medium tw-text-gray-500 tw-truncate"
          >
            {{ item.key }}
          </dt>
          <dd
            class="tw-mt-1 tw-text-3xl tw-leading-9 tw-font-semibold text-primary"
          >
            <div v-if="!loading">
              {{ item.value }}
            </div>
            <div v-else>
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryBookingReport",
  props: {
    summary: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  computed: {
    entriesSummary() {
      return this.summary
        ? Object.entries(this.summary).map(([key, value]) => ({ key, value }))
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.text-primary {
  color: var(--secondary-color) !important;
}
</style>
