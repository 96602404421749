var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header-title"},[_c('h1',{staticClass:"title"},[_vm._v("Booking Report")]),_c('b-button',{staticClass:"btn-filter"},[_c('span',{staticClass:"d-none d-md-block"},[(!_vm.loadingExport)?_c('span',{attrs:{"disabled":_vm.loadingExport},on:{"click":_vm.handleClickExport}},[_vm._v("Export")]):_c('b-spinner',{staticClass:"align-middle"})],1)])],1),_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","fields":_vm.fields,"items":_vm.items,"busy":_vm.isBusy,"show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(createdTime)",fn:function({ item }){return [_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$moment(item.createdTime).locale("th").format("DD/MM/YYYY : LT"))+" ")])]}},{key:"cell(bookingDate)",fn:function({ item }){return [_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$moment(item.bookingDate).format("DD/MM/YYYY"))+" : "+_vm._s(item.timeStart.slice(0, -3))+" ")])]}},{key:"cell(statusName)",fn:function({ item }){return [_c('p',{staticClass:"m-0",class:[
          `${
            item.statusName === 'สำเร็จ'
              ? 'tw-text-green-500'
              : item.statusName === 'กำลังจะมาถึง'
              ? 'tw-text-yellow-500'
              : 'tw-text-red-500'
          }`,
        ]},[_vm._v(" "+_vm._s(item.statusName)+" ")])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-black my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-2"},[_vm._v("Loading...")])],1)]},proxy:true}])}),_c('Pagination',{attrs:{"pageOptions":_vm.pageOptions,"rows":_vm.rows,"filter":_vm.filterPagination},on:{"handleChangeTake":_vm.handleChangeTake,"pagination":_vm.pagination}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }