<template>
  <div class="">
    <div class="tw-flex tw-justify-between tw-mt-6">
      <div></div>
      <b-button class="btn-filter mr-2" @click="handleClickFilter">
        <span class="d-none d-md-block">
          <font-awesome-icon icon="filter" class="pointer" />
          <span class="">Filter</span>
        </span>
      </b-button>
    </div>
    <div class="tw-p-4"><PieChart ref="pieChart" :filter="filter" /></div>
    <div class="">
      <TableRegisterReport :filter="filter" ref="TableRegisterReport" />
    </div>
    <FilterRegisterReportTab
      :visible="visible"
      :filter="filter"
      @clearForm="clearForm"
      :option="option"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
    />
  </div>
</template>

<script>
import TableRegisterReport from "./component/tableRegisterReport.vue";
import PieChart from "./component/pieChart.vue";
import FilterRegisterReportTab from "./component/filter.vue";
export default {
  name: "RegisterReportTab",
  components: { PieChart, FilterRegisterReportTab, TableRegisterReport },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
    option: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      filter: {
        dateRangeId: 0,
      },
    };
  },
  watch: {
    tabIndex(val) {},
  },
  mounted() {},
  methods: {
    handleClickFilter() {
      this.visible = true;
    },
    clearForm() {
      this.visible = false;
    },
    searchAll() {
      this.$refs.TableRegisterReport.fetchTableCustomer();
      this.$refs.pieChart.getPieData();
      this.visible = false;
    },
    clearFilter() {
      this.filter = { dateRangeId: 0 };
      setTimeout(() => {
        this.$refs.TableRegisterReport.fetchTableCustomer();
        this.$refs.pieChart.getPieData();
        this.visible = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
