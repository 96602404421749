<template>
  <div class="">
    <b-form-group label-for="tags-with-dropdown">
      <template #label>
        <div class="tw-flex tw-flex-row tw-w-full tw-justify-between">
          <div class="input-custom">
            <label class="tw-w-2/3">
              {{ label }}
              <span v-if="isRequired" class="tw-text-red-500 tw-text-base"
                >*</span
              >
            </label>
          </div>
          <div class="tw-w-1/4 tw-text-right" v-if="choseAll">
            <b-form-checkbox
              :id="`checkbox-tag-${label}`"
              v-model="status"
              :name="`checkbox-tag-${label}`"
              :value="true"
              :unchecked-value="false"
              @change="onChangeAll"
            >
              all
            </b-form-checkbox>
          </div>
        </div>
      </template>

      <b-form-tags
        :id="`tags-with-dropdown${label}`"
        v-model="value"
        no-outer-focus
        class="mb-2"
        :class="{ 'tw-border-red-500': isValidate }"
        :disabled="status"
      >
        <template v-slot="{ tags, disabled, addTag, removeTag }">
          <ul
            v-if="tags.length > 0"
            class="list-inline d-inline-block mb-2 tw-flex tw-flex-col tw-flex-wrap"
          >
            <li v-for="val in value" :key="val.id" class="list-inline-item">
              <b-form-tag
                @remove="
                  () => {
                    removeTag(val);
                    handleRemove(val);
                  }
                "
                :title="val"
                :disabled="disabled"
                variant="info"
              >
                {{ JSON.parse(val).name }}
              </b-form-tag>
            </li>
          </ul>

          <b-dropdown
            size="sm"
            variant="outline-secondary"
            block
            menu-class="w-100"
            :disabled="status"
            class="dropdown-wrapper"
          >
            <template #button-content>
              <b-icon icon="tag-fill"></b-icon> Choose {{ label }}
            </template>

            <div class="tw-my-2">
              <b-form-group
                label-for="tag-search-input"
                label-cols-md="auto"
                class="mb-0 tw-w-full"
                label-size="sm"
                :description="searchDesc"
                :disabled="disabled"
              >
                <b-form-input
                  v-model="search"
                  :id="`tag-search-input-${label}`"
                  type="search"
                  size="sm"
                  :ref="`input`"
                  :placeholder="`Search ${label}`"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </div>

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-item-button
              class="tw-bg-white tw-border-2 tw-border-red-500 tw-rounded-md"
              v-for="option in availableOptions"
              :key="option.id"
              @click="onOptionClick(option)"
            >
              <div class="tw-flex tw-justify-between tw-w-full">
                <p>{{ option.name }}</p>
                <p class="tw-text-xs tw-text-red-500">
                  {{ selectedOption(option) ? "selected" : "" }}
                </p>
                <p
                  v-if="isAlreadyUsed && option.isAlreadyUse"
                  class="tw-text-xs tw-text-red-500"
                >
                  สาขานี้ถูกสร้างไปแล้ว
                </p>
              </div>
            </b-dropdown-item-button>

            <b-dropdown-text v-if="availableOptions.length === 0">
              There are no tags available to select
            </b-dropdown-text>
          </b-dropdown>
        </template>
      </b-form-tags>
      <!-- </div> -->
    </b-form-group>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false"
        >Please select {{ label }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    vmodel: {
      type: Array,
      required: true,
    },
    choseAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionAll: {
      type: Boolean,
      required: false,
    },
    choseOne: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    isAlreadyUsed: {
      required: false,
      type: Boolean,
      default: false,
    },
    useServerOption: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      value: [],
      status: false,
      backupOptions: [],
    };
  },
  watch: {
    vmodel(val) {
      if (val.length <= 0) {
        this.value = [];
      } else {
        this.defaultValue();
      }
    },
    optionAll(val) {
      this.status = val;
    },
    search(val) {
      this.$emit("onSearch", val);
    },
    options(val, oldVal) {
      if (oldVal.length < val.length) {
        this.backupOptions = val;
      }
    },
  },
  async mounted() {
    this.status = this.optionAll;
    await this.defaultValue();
    this.focusForm();
  },
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },

    availableOptions() {
      const criteria = this.criteria;
      let options = this.backupOptions;

      options = options.filter(
        (opt) => this.value.findIndex((v) => v.id === opt.id) === -1
      );

      if (criteria) {
        options = options.filter(
          (opt) => opt.name.toLowerCase().indexOf(criteria) > -1
        );

        if (this.useServerOption) {
          options = this.options;
        }
      }

      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  methods: {
    async defaultValue() {
      this.backupOptions = await this.options;
      if (this.vmodel.length > 0) {
        this.value = await this.backupOptions.filter((el) =>
          this.vmodel.includes(el.id)
        );
      }
    },
    focusForm() {
      this.$refs.input.focus();
    },
    onOptionClick(option) {
      if (this.choseOne === true) {
        this.value.splice(0, this.value.length);
        this.vmodel.splice(0, this.vmodel.length);
      }

      const { id } = option;
      const existSelected = this.vmodel.find((e) => e === id);

      if (this.isAlreadyUsed) {
        if (option.isAlreadyUse) return;
      }

      if (!existSelected) {
        this.value.push(option);
        this.vmodel.push(option.id);
      }

      this.search = "";
    },
    selectedOption(val) {
      return !!this.value.find((e) => JSON.parse(e).id === val.id);
    },
    onChangeAll() {
      this.value = [];
      this.$emit("onChangeAll");
    },
    handleRemove(val) {
      const { id } = val;
      const findIndex = this.vmodel.findIndex((e) => e === id);
      this.vmodel.splice(findIndex, 1);
    },
  },
};
</script>

<style scoped>
.badge-info {
  background-color: var(--secondary-color);
}
.input-custom {
  padding: 0px;
}
.input-custom > label {
  color: var(--font-main-color);
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
  width: 100%;
}
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
