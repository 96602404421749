<template>
  <div class="tw-mt-4">
    <div class="text-center text-black my-2" v-if="isLoading === true">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">Loading...</strong>
    </div>
    <div v-else lass="tw-py-4">
      <Chart
        :id="id"
        :label="labels"
        type="line"
        :chartData="chartData"
        ref="chart"
        text="Booking"
      />
    </div>
  </div>
</template>

<script>
import Chart from "@/components/form/chart.vue";
export default {
  name: "LineChart",
  components: { Chart },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: "chart",
      labels: [],
      chartData: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getDataChart();
  },
  methods: {
    async getDataChart() {
      this.isLoading = true;
      const payload = {
        ...this.filterPagination,
        ...this.filter,
        startCreateDate:
          this.filter.startCreateDate === ""
            ? null
            : this.filter.startCreateDate,
        endCreateDate:
          this.filter.endCreateDate === "" ? null : this.filter.endCreateDate,
        statusId:
          this.filter.statusId.length <= 0 ? null : this.filter.statusId,
        brandId: this.filter.brandId.length <= 0 ? null : this.filter.brandId,
        venueId: this.filter.venueId.length <= 0 ? null : this.filter.venueId,
        categoryId:
          this.filter.categoryId.length <= 0 ? null : this.filter.categoryId,
        eventId: this.filter.eventId.length <= 0 ? null : this.filter.eventId,
        endBookingDate:
          this.filter.endBookingDate === "" ? null : this.filter.endBookingDate,
        startBookingDate:
          this.filter.startBookingDate === ""
            ? null
            : this.filter.startBookingDate,
        startBookingTime:
          this.filter.startBookingTime === "" ||
          this.filter.startBookingTime === null
            ? null
            : this.$moment(this.filter.startBookingTime).format("HH:mm:ss") ||
              null,
        endBookingTime:
          this.filter.endBookingTime === "" ||
          this.filter.endBookingTime === null
            ? null
            : this.$moment(this.filter.endBookingTime).format("HH:mm:ss") ||
              null,
      };
      const respone = await this.$services.dashboard.getReportBarBooking(
        payload
      );
      this.chartData = respone.detail.datasets;
      this.labels = respone.detail.labels;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
